import { combineReducers } from "redux";

// import reducers here
import testReducer from "./slices/testSlice";
import packReducer from "./slices/packSlice";

// combining reducers for the store
const reducers = combineReducers({
  test: testReducer,
  pack: packReducer,
});

export default reducers;
