import React, { useEffect, useContext } from 'react';
import Helmet from 'react-helmet';
import { useCookies } from 'react-cookie';
import { useForm } from 'react-hook-form';
import axios from 'axios';

import { ThemeContext } from '../../context/ThemeStore';

const ThemeSwitch = ({ isInlineSwitch = false }) => {
  const [cookies] = useCookies();
  const { theme, settings, switchTheme } = useContext(ThemeContext);
  const { register, watch, setValue } = useForm({});
  const themeChange = watch((data, { name, type }) => {
    switchTheme();
  });

  useEffect(() => {
    setValue('appTheme', !theme);
  }, []);

  useEffect(() => {
    const themeSwitch = document.getElementById('switch');
    themeSwitch.checked = theme;
  });

  const changeUserTheme = async () => {
    const updateRequest = await axios.patch(
      `/api/user/profile/`,
      {
        settings: {
          theme: !theme ? 'dark' : 'light',
        },
      },
      {
        headers: { Authorization: `Bearer ${cookies['token']}` },
      }
    );
    if (updateRequest.status === 200) {
      window.localStorage.setItem('userProfile', JSON.stringify(updateRequest.data.data.user));
    }
  };

  return (
    <div>
      <Helmet>
        <link rel="stylesheet" href="/assets/css/app.css" />
        <link rel="stylesheet" href="/assets/css/theme.bundle.css" id="lightTheme" />
        {theme ? <link rel="stylesheet" href="/assets/css/theme-dark.bundle.css" id="darkTheme" /> : ''}
      </Helmet>
      <label id="switch" className={`switch ${isInlineSwitch ? 'switch-inline' : null} d-none d-md-inline-block`}>
        <input
          type="checkbox"
          id="slider"
          {...register('appTheme')}
          onClick={(e) => {
            changeUserTheme();
          }}
        />
        <span className="slider round"></span>
      </label>
    </div>
  );
};

export default ThemeSwitch;
