import React, { useState, useEffect, useContext } from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';
import { Modal } from 'react-bootstrap';
import { useCookies } from 'react-cookie';
import { ThemeContext } from '../../context/ThemeStore';

const NotificationModal = ({ show, toggleShow }) => {
  const [cookies] = useCookies();
  const [notifications, setNotifications] = useState([]);
  const { setMessage } = useContext(ThemeContext);
  const [confirmModal, setConfirmModal] = useState(false);

  useEffect(() => {
    (async () => {
      const fetchNotifications = await axios.get(`/api/auth/user/notifications`, {
        headers: { Authorization: `Bearer ${cookies['token']}` },
      });
      setNotifications(fetchNotifications.data.data);
    })();
    return () => {
      setNotifications([]);
    };
  }, []);

  const clearNotifications = async () => {
    await axios
      .delete(`/api/auth/user/notifications`, {
        headers: { Authorization: `Bearer ${cookies['token']}` },
      })
      .then((res) => {
        setMessage({
          body: 'Successully cleared notifications.',
          theme: 'success',
        });
      })
      .catch((err) => {
        setMessage({
          body: 'Unable to clear notifications.',
          theme: 'error',
        });
      });
    toggleShow();
  };

  const getNotifications = () => {
    if (notifications.length < 1) {
      return <p className="text-muted small">No notifications. All clear! 🔔</p>;
    } else {
      return notifications.map((notification) => {
        return (
          <Link to={notification.onClick.url} key={notification.title}>
            <a className="list-group-item text-reset">
              <div className="row">
                <div className="col-auto">
                  <div className="avatar avatar-sm">
                    <img
                      src={`${notification.icon ? notification.icon : '/assets/img/placeholder.png'}`}
                      alt={notification.title}
                      className="avatar-img rounded-circle"
                    />
                  </div>
                </div>
                <div className="col ms-n2">
                  <h5 className="mb-1">{notification.title}</h5>
                  <p className="small text-gray-700 mb-0 notification-body">{notification.body}</p>
                  <small className="text-muted notification-timestamp">{notification.createdAt}</small>
                </div>
              </div>
            </a>
          </Link>
        );
      });
    }
  };

  const hideConfirmModal = () => {
    setConfirmModal(!confirmModal);
    toggleShow(false);
  };

  const showConfirmModal = () => {
    // toggleShow(false)
    setConfirmModal(true);
  };

  return (
    <div>
      <Modal show={confirmModal} onHide={hideConfirmModal}>
        <div className="modal-content">
          <div className="modal-card card">
            <div className="card-header">
              <h4 className="card-header-title">Clear All Notifications</h4>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
                onClick={hideConfirmModal}
              ></button>
            </div>
            <div className="card-body">Do you want to clear all your notifications?</div>
            <div className="card-footer">
              <div className="row">
                <div className="col"></div>
                <div className="col-auto">
                  <a role="button" data-bs-dismiss="modal" className="btn me-2" onClick={hideConfirmModal}>
                    Cancel
                  </a>
                  <a role="button" className="btn btn-danger" onClick={clearNotifications}>
                    Clear All
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Modal>
      <Modal dialogClassName="modal-dialog-vertical" show={show} onHide={toggleShow}>
        <div className="modal-header">
          <h4 className="modal-title">Notifications</h4>
          {notifications?.length !== 0 ? (
            <a className="small text-primary" onClick={(e) => showConfirmModal()} role="button">
              Clear all
            </a>
          ) : (
            ''
          )}
        </div>
        <div className="modal-body">
          <div className="list-group list-group-flush list-group-activity my-n3">{getNotifications()}</div>
        </div>
      </Modal>
    </div>
  );
};

export default NotificationModal;
