import React, { useContext, useState } from "react";
import { OverlayTrigger } from "react-bootstrap";
import Tooltip from "react-bootstrap/Tooltip";
import Collapse from "react-css-collapse";
import { Link, NavLink } from "react-router-dom";
import NotificationModal from "../dashboard/NotificationModal";
import { ThemeContext } from "../../context/ThemeStore";
import { useSelector } from "react-redux";

const Sidenav = (props) => {
  const [open, setOpen] = useState(false);
  const { settings, user } = useContext(ThemeContext);
  const { showNotification, setShowNotification } = useContext(ThemeContext);
  const recentPack = useSelector((state) => state.pack.recentPack);
  const userProfile = JSON.parse(localStorage.getItem("userProfile"));

  const sideNav = {
    urls: [
      {
        icon: "fe fe-home",
        title: "Home",
        url: "/v2/home",
        active: true,
        target: "_self",
        visible: true,
      },
      // {
      //   icon: "fe fe-book",
      //   title: "Packs",
      //   url: "/test-series",
      //   active: false,
      //   target: "_self",
      //   visible: true,
      // },
      // {
      //   icon: "fe fe-clipboard",
      //   title: "Tests",
      //   url: recentPack && recentPack.version == 2 ? "/v2/dash" : "/dashboard",
      //   active: false,
      //   target: "_self",
      //   visible: recentPack ? true : false,
      // },
      // {
      //   icon: "fe fe-activity",
      //   title: "Analytics",
      //   url: "/v2/overall-pack-analysis",
      //   active: false,
      //   target: "_self",
      //   visible: recentPack && recentPack.version == 2 ? true : false,
      // },
      {
        icon: "fe fe-user",
        title: "Profile",
        url: "/account/",
        active: false,
        target: "_self",
        visible: true,
      },
    ],
  };

  return (
    <nav
      className="navbar navbar-vertical navbar-vertical-sm fixed-start navbar-expand-md navbar-light"
      id="sidebarSmall"
    >
      <div className="container-fluid">
        <button
          className="navbar-toggler"
          type="button"
          aria-controls="sidebarCollapse"
          aria-expanded={!!open}
          aria-label="Toggle navigation"
          onClick={() => setOpen(!open)}
        >
          <span className="navbar-toggler-icon"></span>
        </button>

        <Link to="/v2/home" className="navbar-brand">
          <img
            src={settings.brandIcon}
            className="navbar-brand-img mx-auto brand-icon"
            alt="Quizrr Logo"
          />
          <p className="mt-1 mb-0 text-muted small text-center">Beta</p>
        </Link>

        <div className="navbar-user d-md-none">
          <a
            href="#!"
            onClick={(e) => setShowNotification(!showNotification)}
            className="navbar-user-link me-3"
            data-bs-toggle="modal"
            role="button"
          >
            <span className="icon">
              <i className="fe fe-bell"></i>
            </span>
          </a>

          <div className="dropdown">
            <Link to="/account/profile" className="dropdown-toggle">
              <div className="avatar avatar-sm">
                <img
                  src={user?.profilePicture || `https://avatars.dicebear.com/api/adventurer/${user?.name}.svg`}
                  className="avatar-img rounded-circle"
                  alt={`${user?.googleName} Profile`}
                />
              </div>
            </Link>

            <div
              className="dropdown-menu dropdown-menu-end"
              aria-labelledby="sidebarSmallIcon"
            >
              <Link className="dropdown-item" to="/account/profile">
                Profile
              </Link>
              <Link className="dropdown-item" to="/account/settings">
                Settings
              </Link>
              <hr className="dropdown-divider" />
              <Link className="dropdown-item" to="/logout">
                Logout
              </Link>
            </div>
          </div>
        </div>
        <hr className="navbar-divider d-none d-md-block mt-0 mb-3" />
        <div className="d-none d-md-block">
          <ul className="navbar-nav">
            {sideNav?.urls.map((link, index) => {
              if (link.visible)
                return (
                  <OverlayTrigger
                    key={index}
                    placement="right-end"
                    overlay={
                      <Tooltip
                        className="tooltip d-none d-md-block"
                        id="link-tooltip"
                      >
                        {link.title}
                      </Tooltip>
                    }
                  >
                    {({ ref, ...triggerHandler }) => (
                      <li
                        className="nav-item"
                        {...triggerHandler}
                        ref={ref}
                        key={index}
                      >
                        <NavLink
                          strict
                          to={{ pathname: link.url }}
                          target={link.target}
                          activeClassName="active"
                          className="nav-link"
                        >
                          <i className={link.icon}></i>{" "}
                          <span className="left-nav-text">{link.title}</span>
                        </NavLink>
                      </li>
                    )}
                  </OverlayTrigger>
                );
            })}
          </ul>
        </div>
        <Collapse
          isOpen={open}
          id="sidebarCollapse"
          className="navbar-collapse"
        >
          <ul className="navbar-nav">
            {sideNav?.urls.map((link, index) => {
              return (
                <OverlayTrigger
                  key={index}
                  placement="right-end"
                  overlay={
                    <Tooltip
                      className="tooltip d-none d-md-block"
                      id="link-tooltip"
                    >
                      {link.title}
                    </Tooltip>
                  }
                >
                  {({ ref, ...triggerHandler }) => (
                    <li
                      className="nav-item"
                      {...triggerHandler}
                      ref={ref}
                      key={index}
                    >
                      <NavLink
                        strict
                        to={{ pathname: link.url }}
                        target={link.target}
                        activeClassName="active"
                        className="nav-link"
                      >
                        <i className={link.icon}></i>{" "}
                        <span className="d-md-none">{link.title}</span>
                      </NavLink>
                    </li>
                  )}
                </OverlayTrigger>
              );
            })}
          </ul>
        </Collapse>

        <div className="mt-auto"></div>

        <div
          className="navbar-user d-none d-md-flex flex-column"
          id="sidebarSmallUser"
        >
          <div className="dropend">
            <Link to="/account/profile" className="dropdown-toggle">
              <div className="avatar avatar-sm">
                <img
                  src={userProfile?.profilePicture || `https://avatars.dicebear.com/api/adventurer/${user?.name}.svg`}
                  className="avatar-img rounded-circle"
                  alt={`${userProfile?.googleName} Profile`}
                />
              </div>
            </Link>

            <div
              className="dropdown-menu"
              aria-labelledby="sidebarSmallIconCopy"
            >
              <Link className="dropdown-item" to="/account/profile">
                Profile
              </Link>
              <Link className="dropdown-item" to="/account/settings">
                Settings
              </Link>
              <hr className="dropdown-divider" />
              <Link className="dropdown-item" to="/logout">
                Logout
              </Link>
            </div>
          </div>
        </div>
      </div>
    </nav>
  );
};

export default Sidenav;
