import React, { useContext } from "react";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import { ThemeContext } from "../../context/ThemeStore";

const schedule = process.env.REACT_APP_SCHEDULE_PDF;

const BottomNav = () => {
  const { isDarkTheme } = useContext(ThemeContext);
  const packDetails = useSelector((state) => state.pack.recentPack);
  return (
    <>
      <nav
        className="navbar navbar-expand-lg navbar-light bottom-navbar d-md-none py-0"
        id="bottomNav"
      >
        <div className="container-fluid">
          <ul className="navbar-nav bottom-navbar-navbar-nav me-lg-auto row mx-0">
            <li className="col-3 nav-item text-center">
              <Link
                activeClassName={`${
                  isDarkTheme ? "mobile-nav-active" : "mobile-nav-active-dark"
                }`}
                className="nav-link px-0"
                to="/v2/home"
              >
                <i className="fe fe-home"></i>
                <span className="d-md-none" style={{ fontSize: "12px" }}>
                  Home
                </span>
              </Link>
            </li>
            <li className="col-3 nav-item text-center">
              <Link
                activeClassName={`${
                  isDarkTheme ? "mobile-nav-active" : "mobile-nav-active-dark"
                }`}
                className="nav-link px-0"
                to="/test-series"
              >
                <i className="fe fe-book"></i>
                <span className="d-md-none" style={{ fontSize: "12px" }}>
                  Packs
                </span>
              </Link>
            </li>
            <li className="col-3 nav-item text-center">
              <Link
                activeClassName={`${
                  isDarkTheme ? "mobile-nav-active" : "mobile-nav-active-dark"
                }`}
                className="nav-link px-0"
                to={
                  packDetails && packDetails?.version == 2
                    ? `/v2/dash?packId=${packDetails?._id}`
                    : `/dashboard?pack=${packDetails?.slug}`
                }
              >
                <i className="fe fe-clipboard"></i>
                <span className="d-md-none" style={{ fontSize: "12px" }}>
                  Tests
                </span>
              </Link>
            </li>
            <li className="col-3 nav-item text-center">
              <Link
                strict
                to="/account/"
                activeClassName={`${
                  isDarkTheme ? "mobile-nav-active" : "mobile-nav-active-dark"
                }`}
                className="nav-link px-0"
              >
                <i className="fe fe-user"></i>
                <span className="d-md-none" style={{ fontSize: "12px" }}>
                  Profile
                </span>
              </Link>
            </li>
          </ul>
        </div>
      </nav>
    </>
  );
};

export default BottomNav;
