import React, { useContext, useEffect } from "react";
import Sidenav from "../components/sidenav";
import ThemeSwitch from "../components/global/ThemeSwitch";
import { useHistory } from "react-router-dom";
import { useCookies } from "react-cookie";
import Cookies from 'js-cookie'
import BottomNav from "../components/global/BottomNav";
import ChangeTheme from "../components/global/ChangeTheme";
import { ThemeContext } from "../context/ThemeStore";

const Dashboard = ({ children }) => {
  const [cookies] = useCookies();
  const router = useHistory();
  const { user } = useContext(ThemeContext);

  useEffect(() => {
    if (!Cookies.get('token')) {
      router.push(`/auth?redirect_url=${window.location.pathname + window.location.search}`);
    }
    const user = JSON.parse(localStorage.getItem('userProfile'))
    if (user && !user.isOnboardingDone) router.push(`/onboarding?step=1`)
  }, []);

  return (
    <div>
      <Sidenav />
      <ChangeTheme />
      <div className="main-content mb-5 mb-sm-0 pb-4 pb-sm-0">{children}</div>
    </div>
  );
};

export default Dashboard;
