import { createSlice, current } from '@reduxjs/toolkit';
import { toInteger } from 'lodash';
import Axios from 'axios';

export const testSlice = createSlice({
  name: 'test',
  initialState: {
    status: 'main', // main, bonus, submissionMain, submissionBonus
    timeLeft: 0,
    testDetails: {},
    sessionDetails: {},
    currentQuestion: {},
    currentSection: {},
    currentSubject: '',
    sections: [],
    bonusSections: [],
    ftbAnswers: [],
  },
  reducers: {
    setStatus: (state, action) => {
      state.status = action.payload;
    },
    setTimer: (state, action) => {
      state.timeLeft = action.payload;
    },
    decrementTime: (state, action) => {
      state.timeLeft -= action.payload;
    },
    setTestDetails: (state, action) => {
      state.testDetails = action.payload;
    },
    setSessionDetails: (state, action) => {
      state.sessionDetails = action.payload;
    },
    setCurrentSection: (state, action) => {
      state.currentSection = action.payload;
      state.currentQuestion = action.payload.questions[0];
    },
    setCurrentQuestion: (state, action) => {
      state.currentQuestion = action.payload;
    },
    setCurrentSubject: (state, action) => {
      state.currentSubject = action.payload;
    },
    setSections: (state, action) => {
      state.sections = action.payload;
    },
    setBonusSections: (state, action) => {
      state.bonusSections = action.payload;
    },
    editQuestionState: (state, action) => {
      const { shift, updateCurrent } = action.payload;
      const { sectionIndex, questionIndex } = action.payload;
      const original = state.sections[sectionIndex].questions[questionIndex];
      const newState = {
        ...original,
        ...shift,
        timeLeftWhenAttempted: toInteger(state.timeLeft / 60),
        timeTaken: shift.timeTaken ? (original?.timeTaken || 0) + shift.timeTaken : original?.timeTaken || 0,
      };
      state.sections[sectionIndex].questions[questionIndex] = newState;
      state.currentSection = state.sections[sectionIndex];
      if (updateCurrent) state.currentQuestion = state.sections[sectionIndex].questions[questionIndex];
    },
    switchToBonus: (state, action) => {
      const sections = state.sections;
      const bonusSections = state.bonusSections;
      state.currentSection = bonusSections[0];
      state.currentQuestion = bonusSections[0].questions[0];
      state.sections = bonusSections;
      state.bonusSections = sections;
      state.status = 'bonus';
    },
    setFTBAnswers: (state, action) => {
      const { questionId, qIndex, answer } = action.payload;
      const newFTBAnswers = [...state.ftbAnswers];
      const localQuestionIndex = newFTBAnswers.findIndex((question) => question.questionId === questionId);
      if (localQuestionIndex === -1) {
        newFTBAnswers.push({ questionId, qIndex, answer });
      } else {
        newFTBAnswers[localQuestionIndex].answer = answer;
      }
      state.ftbAnswers = newFTBAnswers;
      console.log('State FTB Answers: ', current(state));
    },
    resetFTBAnswers: (state, action) => {
      state.ftbAnswers = [];
    },
  },
});

export const {
  setStatus,
  setTimer,
  decrementTime,
  setTestDetails,
  setSessionDetails,
  setCurrentSection,
  setCurrentQuestion,
  setCurrentSubject,
  setSections,
  setBonusSections,
  editQuestionState,
  switchToBonus,
  setFTBAnswers,
  resetFTBAnswers,
} = testSlice.actions;

export const saveAnswer = (token, payload) => async (dispatch, getState) => {
  try {
    const SI = payload.sectionIndex;
    const QI = payload.questionIndex;
    dispatch(editQuestionState(payload)); // update state locally
    let currentQuestion = getState().test.sections[SI].questions[QI]; // get updated state
    let questionData = { ...currentQuestion };
    if (payload?.questionId) questionData.questionId = payload.questionId;
    const res = await Axios.post(`/api/test/tempTestDetails/${currentQuestion.testId}`, questionData, {
      headers: { Authorization: `Bearer ${token}` },
    });
    return res;
  } catch (err) {
    console.log(err);
    return err;
  }
};

export const saveSubject =
  (token, subjectId, force = false) =>
  async (dispatch, getState) => {
    try {
      const subjectNotMatch = getState().test.currentSubject !== subjectId;
      if (force || subjectNotMatch) {
        dispatch(setCurrentSubject(subjectId));
        const testId = getState().test.testDetails._id;
        const res = await Axios.post(
          `/api/test/tempTestDetails/${testId}`,
          {
            testId,
            type: 'subjectMovement',
            movement: { subject: subjectId },
          },
          { headers: { Authorization: `Bearer ${token}` } }
        );
        return res;
      }
      return null;
    } catch (err) {
      console.log(err);
      return err;
    }
  };

export const saveTimer = (token) => async (dispatch, getState) => {
  try {
    const testId = getState().test.testDetails._id;
    const res = await Axios.patch(`/api/test/timeUpdation/${testId}`, null, {
      headers: { Authorization: `Bearer ${token}` },
    });
    return res;
  } catch (err) {
    console.log(err);
    return err;
  }
};

export const saveBonus = (token) => async (dispatch, getState) => {
  try {
    const testId = getState().test.testDetails._id;
    const res = await Axios.patch(
      `/api/test/bonusAttempt/${testId}`,
      { bonusAttempt: true },
      { headers: { Authorization: `Bearer ${token}` } }
    );
    if (res.status === 200) dispatch(switchToBonus());
    return res;
  } catch (err) {
    console.log(err);
    return err;
  }
};

export const submitTest = (token, pack, router) => async (dispatch, getState) => {
  try {
    const testId = getState().test.testDetails._id;
    const res = await Axios.get(`/api/test/analysis/${pack}/${testId}`, {
      headers: { Authorization: `Bearer ${token}` },
    });
    if (res.status === 200) router.push(`/tests/${pack}/${testId}/submission`);
    return res;
  } catch (err) {
    console.log(err);
    return err;
  }
};

export default testSlice.reducer;
