import React, { useState, useEffect } from "react";

const ThemeContext = React.createContext();
const SchedulePDF = process.env.REACT_APP_SCHEDULE_PDF;
// const SchedulePDF = localStorage.getItem('schedulePdf') || 'https://quizrr.in/schedules'

const settingStore = {
  dark: {
    title: "Quizrr",
    logo: "/assets/img/logo-dark.svg",
    brandIcon: "/assets/img/brand-icon-dark.svg",
    url: "https://quizrr.in",
    supportEmail: "support@mathongo.com",
    indianFlag: "/assets/img/india_flag.png"
  },
  light: {
    title: "Quizrr",
    logo: "/assets/img/logo.svg",
    brandIcon: "/assets/img/brand-icon.svg",
    url: "https://quizrr.in",
    supportEmail: "support@mathongo.com",
    indianFlag: "/assets/img/india_flag.png"
  },
};

const ThemeStore = ({ children }) => {
  const [theme, setTheme] = useState(true);
  const [user, setUser] = useState({});
  const [settings, setSettings] = useState(settingStore.dark);
  const [showNotification, setShowNotification] = useState(false);
  const [showToastr, setShowToastr] = useState(false);
  const [toastrMessage, setToastrMessage] = useState({});
  const [siteTitle, setSiteTitle] = useState("Quizrr");
  const [isDarkTheme, setIsDarkTheme] = useState(true);

  const setMessage = (message) => {
    setShowToastr(true);
    setToastrMessage(message);
  };

  const setThemeSettings = (isDark) => {
    if (isDark) {
      setSettings(settingStore.dark);
    } else {
      setSettings(settingStore.light);
    }
  };

  const switchTheme = () => {
    setTheme(!theme);
  };

  useEffect(() => {
    (() => {
      const userProfile = localStorage.getItem("userProfile");
      setUser(JSON.parse(userProfile));
    })();
  }, []);

  return (
    <ThemeContext.Provider
      value={{
        theme,
        switchTheme,
        settings,
        user,
        showNotification,
        setShowNotification,
        showToastr,
        setShowToastr,
        toastrMessage,
        setMessage,
        setUser,
        setThemeSettings,
        siteTitle,
        isDarkTheme,
        setIsDarkTheme,
      }}
    >
      {children}
    </ThemeContext.Provider>
  );
};

export { ThemeContext, ThemeStore };
