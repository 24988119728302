import React, { useEffect } from 'react';
import { CookiesProvider } from 'react-cookie';
import { Helmet } from 'react-helmet';
import { useCookies } from 'react-cookie';
import { ClearCacheProvider } from 'react-clear-cache';
import { ErrorBoundary } from 'react-error-boundary';
import axios from 'axios';

import Router from './router';
import ErrorPage from './pages/error';
import Push from './components/global/Push';
import Logger from './components/global/Logger';
import { ThemeStore } from './context/ThemeStore';

import './styles/common.scss';
// import 'vanillatoasts/vanillatoasts.css';

const App = () => {
  const [cookies] = useCookies();
  useEffect(() => {
    const setCookieHeader = async () => {
      axios.defaults.headers.common['Authorization'] = `Bearer ${cookies['token']}`;
    };
    setCookieHeader();
  }, []);

  return (
    <ErrorBoundary
      onError={(error, componentStack) => {
        console.log({ error, componentStack });
        Logger({ error, componentStack, location: window.location });
      }}
      fallbackRender={({ resetErrorBoundary }) => <ErrorPage type={503} resetErrorBoundary={resetErrorBoundary} />}
    >
      <ClearCacheProvider auto={true}>
        <ThemeStore>
          <Helmet>
            <meta charset="UTF-8" />
            <meta http-equiv="X-UA-Compatible" content="IE=edge" />
            <meta name="robots" content="imageindex, follow, snippet, index" />
            <meta name="viewport" content="width=device-width, initial-scale=1.0" />
            <title>{`IIT, JEE Mains | MathonGo`}</title>
            <link rel="manifest" href="/manifest.json" />
            <link href="/icons/favicon-16x16.png" rel="icon" type="image/png" sizes="16x16" />
            <link href="/icons/favicon-32x32.png" rel="icon" type="image/png" sizes="32x32" />
            <link rel="apple-touch-icon" sizes="180x180" href="/apple-touch-icon.png"></link>
            <link rel="icon" type="image/png" sizes="32x32" href="/favicon-32x32.png"></link>
            <link rel="icon" type="image/png" sizes="16x16" href="/favicon-16x16.png"></link>
            <link rel="mask-icon" href="/safari-pinned-tab.svg" color="#2e005b"></link>
            <meta name="theme-color" content="#317EFB" />
            <link
              href="https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600&display=swap"
              rel="stylesheet"
            />
          </Helmet>
          <CookiesProvider>
            <Router />
            <Push />
          </CookiesProvider>
        </ThemeStore>
      </ClearCacheProvider>
    </ErrorBoundary>
  );
};

export default App;
