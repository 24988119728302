import React, { useEffect } from "react";
import { useCookies } from "react-cookie";
import { useHistory, useLocation } from "react-router-dom";
import Dashboard from "../layout/dashboard";

const useQuery = () => new URLSearchParams(useLocation().search);

const Index = () => {
  const router = useHistory();
  const query = useQuery();
  const [cookies] = useCookies();

  // Extract the UTM source from the URL
  const utmSource = query.get('utm_source');
  const utmMedium = query.get('utm_medium');
  const utmCampaign = query.get('utm_campaign');

  useEffect(() => {
    if (cookies["token"]) {
      router.push("/v2/home");
    } else {
      router.push(`/auth?utm_source=${utmSource}&utm_medium=${utmMedium}&utm_campaign=${utmCampaign}`);
    }
  }, []);

  return <Dashboard />;
};

export default Index;
