import React, { useState, useEffect } from 'react';
import Axios from 'axios';
import { Link, useLocation, useHistory } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { useCookies } from 'react-cookie';
import { yupResolver } from '@hookform/resolvers/yup';
// import vanillatoasts from 'vanillatoasts';
import { error } from 'tata-js';
import * as Yup from 'yup';

import styles from './auth.module.scss';
import Loader from '../../components/global/Loader';

const useQuery = () => new URLSearchParams(useLocation().search);

const validationSchema = Yup.object().shape({
  name: Yup.string()
    .required('Name is required')
    .matches(/^[a-zA-Z]+((['. ][a-zA-Z ])?[a-zA-Z]*)*$/, {
      message: 'Name should only contain letters and spaces',
    })
    .min(3, 'Name should have atleast 3 letters')
    .max(25, ' Name should have atmost 25 letters'),
  email: Yup.string().required('Email is required').email('Email is invalid'),
});

const RegisterForm = ({ props }) => {
  const [cookies, setCookie] = useCookies();
  const [loading, setLoading] = useState(false);
  const [showDetails, setShowDetails] = useState(false);
  const [loginStatus, setLoginStatus] = useState(0);
  const router = useHistory();
  const query = useQuery();
  const phoneNumber = query.get('phoneNumber');
  // Extract the UTM source from the URL
  const utmSource = query.get('utm_source');
  const utmMedium = query.get('utm_medium');
  const utmCampaign = query.get('utm_campaign');

  const {
    register,
    handleSubmit,
    formState: { errors, isValid },
  } = useForm({
    mode: 'onChange',
    reValidateMode: 'onChange',
    resolver: yupResolver(validationSchema),
  });

  useEffect(() => {
    if(!phoneNumber || phoneNumber.length != 10) {
      router.push("/auth");
    }
  }, [])

  useEffect(() => {
    const checkLoginStatus = () => {
      if (cookies['token']) {
        window.location.href = '/v2/home';
      }
    };
    if (loginStatus === 0) {
      checkLoginStatus();
    }
    return () => {};
  }, [loginStatus]);

  const onSubmit = (data) => {
    setLoading(true);
    const { name, email, password } = data;
    Axios.post(`/api/auth/user/manualRegister/v2`, {
      name,
      email,
      password,
      phoneNumber,
      utmSource,
      utmMedium,
      utmCampaign
    })
      .then((res) => {
        if (res?.status == 200) {
          const data = res.data;
          setCookie('token', data.data.token, { path: '/', maxAge: 604799 });
          localStorage.setItem('userProfile', JSON.stringify(data.data.user));
          window.location.href = '/v2/home?onBoarding=completed';
        } else {
          setLoading(false);
          error('Could not complete on-baording', "Please check your email and ensure that you haven't used email already.", {
            position: 'tr',
            duration: 5000,
          });
        }
      })
      .catch((error) => {
        setLoading(false);
        error('Could not complete on-boarding.', "Please check your email and ensure that you haven't used this email already.", {
          position: 'tr',
          duration: 5000,
        });
      });
  };

  return (
    <div className={styles.app}>
      {showDetails ? (
        <div className='container'>
          <div className='row justify-content-center'>
            <div className='col-10 my-5'>
              <h1 className='display-4 mb-3'>Email Verification</h1>

              <p className='mb-4'>
                A verification link has been sent to your email. Please follow the instructions specified in the email.<br /><br />
                <span className='text-danger fw-bolder'>Not able to find the email?</span><br /><br />
                1. Please make sure to check your <span className='text-danger fw-bold'>Updates/Promotions/Spam</span> folders for the verification email.<br /><br />
                2. It might be possible that you've entered an incorrect email. In that case, please refresh this page and register again using a correct email id.
              </p>
            </div>
            
          </div>
        </div>
      ) : (
        <div className='container'>
          <div className='row justify-content-center'>
            <div className='col-12 col-md-5 col-xl-4 my-5'>
              <h1 className='display-4 text-center mb-3'>Welcome 👋</h1>

              <p className='text-muted text-center mb-5'>Please complete your account creation.</p>

              <form role='form' onSubmit={handleSubmit(onSubmit)}>
                <div className='form-group'>
                  <label className='form-label'>Name</label>

                  <input {...register('name')} name='name' type='text' className='form-control' placeholder='Enter your name' />

                  {errors && errors?.name && <span className='invalid-feedback d-block mt-2'>{errors?.name?.message}</span>}
                </div>

                <div className='form-group'>
                  <label className='form-label'>Email Address</label>

                  <input {...register('email')} autoComplete='off' name='email' type='email' className='form-control' placeholder='Enter your email' />

                  {errors && errors?.email && <span className='invalid-feedback d-block mt-2'>{errors?.email?.message}</span>}
                </div>

                {loading ? (
                  <div className='text-center'>
                    <div className='spinner-border text-primary' role='status'>
                      <span className='visually-hidden'>Loading...</span>
                    </div>
                  </div>
                ) : (
                  <button className={`btn btn-lg w-100 btn-primary mb-3 ${!isValid && 'disabled'}`}>Save & Continue</button>
                )}
              </form>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

const RegisterVerify = ({ props }) => {
  const query = useQuery();
  const [cookies] = useCookies();
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(true);
  const [loginStatus, setLoginStatus] = useState(0);

  const token = query.get('token');

  useEffect(() => {
    const checkLoginStatus = () => {
      if (cookies['token']) {
        window.location.href = '/v2/home';
      }
    };
    if (loginStatus === 0) {
      checkLoginStatus();
    }
    return () => {};
  }, [loginStatus]);

  useEffect(() => {
    if (token && token.length > 0) {
      setLoading(true);
      setError(false);
      Axios.post(`/api/auth/user/verifyEmail`, { token })
        .then((res) => {
          setLoading(false);
          console.log(res);
        })
        .catch((err) => {
          setError(true);
          setLoading(false);
          console.log(err);
        });
    } else {
      setError(true);
      setLoading(false);
    }
  }, [token]);

  return (
    <div className={styles.app}>
      {loading ? (
        <Loader />
      ) : (
        <div className='container'>
          <div className='row justify-content-center'>
            <div className='col-12 col-md-5 col-xl-4 my-5'>
              <h1 className='display-4 text-center mb-3'>{!error ? 'Email Verified' : "Email couldn't be verified"}</h1>

              {!error ? (
                <p className='text-muted text-center mb-4'>
                  Your email has been verified. You can log in to your account from <Link to='/auth/login'>here</Link>
                </p>
              ) : (
                <p className='text-muted text-center mb-4'>Your email could not be verified. Please check your email for a new verification link or contact us.</p>
              )}
            </div>
            <div className='text-center'>
              <small className='text-muted text-center'>
                Having trouble ?
                <br />
                <a href='mailto:support@mathongo.com?subject=I cannot login to Quizrr'> Contact us</a>
              </small>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export { RegisterForm, RegisterVerify };
