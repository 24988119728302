import axios from "axios";
import React, { useContext, useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { ThemeContext } from "../../context/ThemeStore";

const ChangeTheme = ({ isInlineSwitch = false }) => {
  // const [isDarkTheme, setIsDarkTheme] = useState(false);
  const {
    user,
    setUser,
    switchTheme,
    setThemeSettings,
    isDarkTheme,
    setIsDarkTheme,
  } = useContext(ThemeContext);

  const onThemeChange = (e) => {
    if (user) {
      axios
        .patch(`/api/user/profile/settings`, { isDarkTheme: !isDarkTheme })
        .then((response) => {
          if (response?.status === 200) {
            setUser(response.data.data);
            localStorage.setItem(
              "userProfile",
              JSON.stringify(response.data.data)
            );
          }
        })
        .catch((err) => console.log(err));
    }
    setIsDarkTheme(!isDarkTheme);
    setThemeSettings(!isDarkTheme);
  };

  useEffect(() => {
    const localUser = JSON.parse(localStorage.getItem("userProfile"));
    if (!localUser) {
      setIsDarkTheme(false);
      setThemeSettings(false);
      return null;
    }
    const { settings } = localUser;
    if (settings) {
      const { isDarkTheme } = settings;
      setIsDarkTheme(isDarkTheme);
      setThemeSettings(isDarkTheme);
    }
  }, []);

  useEffect(() => {
    switchTheme(isDarkTheme);
  }, [isDarkTheme]);
  return (
    <div>
      <div>
        <label
          id="switch"
          className={`switch ${
            isInlineSwitch ? "switch-inline" : "d-none d-md-inline-block"
          }`}
        >
          <input
            type="checkbox"
            id="slider"
            checked={!isDarkTheme}
            onChange={onThemeChange}
          />
          <span className="slider round"></span>
        </label>
      </div>
    </div>
  );
};

export default ChangeTheme;
