import { createSlice } from "@reduxjs/toolkit";

export const packSlice = createSlice({
  name: "pack",
  initialState: {
    recentPack: JSON.parse(localStorage.getItem("recentPack")) || null,
  },
  reducers: {
    setRPack: (state, action) => {
      state.recentPack = action.payload;
    },
  },
});

export const { setRPack } = packSlice.actions;

export default packSlice.reducer;
